// Generated by Framer (ff86393)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getLoadingLazyAtYPosition, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import AuthorTooltipIndicator from "./WdEbo2ZHT";
const AuthorTooltipIndicatorFonts = getFonts(AuthorTooltipIndicator);

const serializationHash = "framer-uMTdi"

const variantClassNames = {oPXwKVdoR: "framer-v-1j5rinw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, image, tap, width, ...props}) => { return {...props, KorTF0FGk: image ?? props.KorTF0FGk, ZcUtktVvC: tap ?? props.ZcUtktVvC} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KorTF0FGk, ZcUtktVvC, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "oPXwKVdoR", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap2ydqx3 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (ZcUtktVvC) {const res = await ZcUtktVvC(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), sizes: componentViewport?.width || "100vw", ...toResponsiveImage(KorTF0FGk)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1j5rinw", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"oPXwKVdoR"} onTap={onTap2ydqx3} ref={ref ?? ref1} style={{borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><motion.div className={"framer-1m3ju1g"} layoutDependency={layoutDependency} layoutId={"vcR4EzSWM"}><motion.div className={"framer-12mid3n"} layoutDependency={layoutDependency} layoutId={"f76tM9AIc"} style={{backgroundColor: "var(--token-38e83481-a340-4535-b223-ac61fd3243f4, rgb(255, 255, 255))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}}/><ComponentViewportProvider height={8} width={"8px"} y={(((((componentViewport?.y || 0) + (componentViewport?.height || 64)) - 14) + 10) - 9)}><motion.div className={"framer-1he971k-container"} layoutDependency={layoutDependency} layoutId={"FS251bjMG-container"}><AuthorTooltipIndicator height={"100%"} id={"FS251bjMG"} layoutId={"FS251bjMG"} QbOKTnai0={"rgb(27, 198, 81)"} style={{height: "100%", width: "100%"}} variant={"cDS9_8JVf"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-uMTdi.framer-s4wjpj, .framer-uMTdi .framer-s4wjpj { display: block; }", ".framer-uMTdi.framer-1j5rinw { cursor: pointer; height: 64px; position: relative; width: 64px; }", ".framer-uMTdi .framer-1m3ju1g { align-content: center; align-items: center; aspect-ratio: 1 / 1; bottom: 4px; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: var(--framer-aspect-ratio-supported, 10px); justify-content: center; overflow: visible; padding: 0px; position: absolute; right: 3px; width: 10px; }", ".framer-uMTdi .framer-12mid3n { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 10px); overflow: visible; position: relative; width: 10px; }", ".framer-uMTdi .framer-1he971k-container { aspect-ratio: 1 / 1; bottom: 1px; flex: none; height: var(--framer-aspect-ratio-supported, 8px); position: absolute; right: 1px; width: 8px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-uMTdi .framer-1m3ju1g { gap: 0px; } .framer-uMTdi .framer-1m3ju1g > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-uMTdi .framer-1m3ju1g > :first-child { margin-top: 0px; } .framer-uMTdi .framer-1m3ju1g > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"KorTF0FGk":"image","ZcUtktVvC":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerTmNzC7Bon: React.ComponentType<Props> = withCSS(Component, css, "framer-uMTdi") as typeof Component;
export default FramerTmNzC7Bon;

FramerTmNzC7Bon.displayName = "Buttons / BaC Image";

FramerTmNzC7Bon.defaultProps = {height: 64, width: 64};

addPropertyControls(FramerTmNzC7Bon, {KorTF0FGk: {title: "Image", type: ControlType.ResponsiveImage}, ZcUtktVvC: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerTmNzC7Bon, [{explicitInter: true, fonts: []}, ...AuthorTooltipIndicatorFonts], {supportsExplicitInterCodegen: true})